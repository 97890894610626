<template>
  <div class="container my-2 p-0">
    <div class="e-top">
        <div class="bg-white p-1 text-center fs-9">
            <a @click="totalNumber">总({{ data.counts.total_number }})</a> | <a @click="onTheJob">在职({{ data.counts.on_the_job }})</a> |
            <a @click="quid">离职({{ data.counts.quit }})</a> | <a @click="stayOnTheJob">待报到({{ data.counts.stay_on_the_job }})</a> | <a @click="day">今天({{ data.counts.today }})</a>
        </div>
        <search></search>
    </div>  
    <div class="bg-white p-2 my-2 px-3" v-for="(items,index) in data.data" :key="index">
      <div class="radius-5">
        <table class="border w-100 e-table">
          <tr>
            <th class="text-center" rowspan="6">
              <a href="">
                <img style="height: 65px; width: 55px" :src="items.resume.avatar" />
                <p class="text-center m-0 mt-1">{{ items.resume.nickname }}</p>
              </a>
            </th>
          </tr>
          <tr>
            <td>
                {{ items.settlement.name }}: {{ items.settlement.price}}
            </td>
          </tr>
          <tr>
            <td>
              {{ items.resume.name }}: <a class="calltel" :href="`tel:${items.resume.phone}`">{{ items.resume.phone}}</a>
            </td>
          </tr>

          <tr>
            <td>
                报道时间： {{ items.report_time }}
            </td>
          </tr>

          <tr>
            <td>
              入职时间： {{ items.on_the_time }}
            </td>
          </tr>
          <tr>
            <td>
              离职时间：{{ items.quit_time }}
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-center py-1">
              <button class="btn btn-sm btn-outline-info mx-1 py-0" @click="operate(items.id, items.resume.name)">操 作</button>
              <label class="ps-5  py-0">状 态:</label>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <jmodal v-if="showModal" @close="showModal=false">
      <template v-slot:title>
          {{ mTitle }}
      </template>
      <template v-slot:content>
          <div class="p-1">
              <div class="form-floating py-1">
                <input type="date" class="form-control" id="date1">
                <label for="date1">报道时间</label>
              </div>
              <div class="form-floating py-1">
                <input type="date" class="form-control" id="date2">
                <label for="date2">入职时间</label>
              </div>
              <div class="form-floating py-1">
                <input type="date" class="form-control" id="date3">
                <label for="date3">离职时间</label>
              </div>
              <div class="form-floating py-1">
                <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                  <option v-for="(val, index) in WORK_STATUS" :key="index">{{ val }}</option>
                </select>
                <label for="floatingSelect">状态</label>
              </div>
              <div class="form-floating py-1">
                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                <label for="floatingTextarea2">备 注</label>
              </div>
              <div class="p-2 w-100 text-end">
                   <button class="btn btn-sm btn-outline-success mx-2" @click="submit">提 交</button>
                   <button class="btn btn-sm btn-outline-secondary mx-2" @click="showModal=false">取 消</button>
              </div>
          </div>
      </template>
  </jmodal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Axios from 'axios'
import { WORK_STATUS } from '@/lib/data'
import { getUrlCode } from '@/lib/unitls'
const data = ref({
  counts: {
    total_number: 1888, //总投人数
    on_the_job: 588, //在职
    quit: 68, //离职
    stay_on_the_job: 100, //待入职
    today: 58 //今天入职
  },
  data: []
})

const showModal = ref(false)
const showResume = ref(false)
const mTitle =ref()
const a = 5

for(let i=0; i<a; i++) {
    data.value.data.push(
      {
        id: 100 + i,
        job_id: 100,
        settlement: {
          id: 1,
          name: '日结',
          type: 1, //结算方式
          price: 18 //单价
        },
        status: 1, //状态
        report_time: '2022-03-02', //报道时间
        on_the_time: '2020-03-20', //入职时间
        quit_time: '2020-03-30', //离职时间
        resume: {
          id: 200 + i, //简历id
          uid: 1000 + i, //投递者id
          nickname: 'simon', //微信昵称
          name: '陈一龙', //姓名
          avatar: 'https://picsum.photos/70/70', //头像
          phone: '138180180180', //电话
          contacts: '刘德华', //紧急联系人
          updat_time: '2022-03-03'
        }
      }
    )
}
const itemsId = ref()
function operate(ids, title) {
  mTitle.value = title
  showModal.value = true
  itemsId.value = ids
}

function submit(ids) {
  if(confirm('提交成功' + ids + '是否关闭当前窗口？')) {
    showModal.value = false
  }
}

//获取数据
function getData(obj) {
  console.log(obj)
  Axios.get(obj ? { path: 'http://localhost:5000/work', query: obj } : 'http://localhost:5000/signups' ).then((response)=>{
    if(response.data.code == 0) {
      // data.value = response.data.data
    }
  })
}
//查看所有
function totalNumber() {
  getData({check: 'total_numger'})
}
//查看在职
function onTheJob() {
  getData({check: 'on_the_job'})
}
//查看离职
function quit() {
  getData({check: 'quid'})
}
//查看待入职
function stayOnTheJob() {
  getData({check: 'stay_on_the_job'})
}
//查看今天
function day() {
  getData({check: 'today'})
}
onMounted(() => {
  window.scrollTo(0, 0)
  document.title = getUrlCode('title')
  getData(getUrlCode('job_id'))
})


</script>

<style scoped>
.e-top {
    position: sticky;
    top: 0;
}
.e-table th {
  width: 60px;
}
.e-table tr td {
  line-height: 0.9rem;
  padding-left: 2px;
  font-size: 0.8em;
  border: 1px solid #dee2e6!important;
  padding: 2px;
}
.mcontent p {
 margin-bottom: 0;
}

.timeline {
  position: relative;
  border-left: 1px solid #E5E5E5;
  height: 40px;
  margin-left: 100px;
}
.timeline span {
  position: absolute;
  top: -17px;
  left: -90px;
  font-size: 14px;
  font-weight: 300;
  color: #999999;
  line-height: 36px;
}
.timeline p {
  position: absolute;
  left: 15px;
  top: -6px;
  font-size: 10px;
  font-weight: 400;
  color: #666666;
  line-height: 1rem;
}
.timeline::before {
  content: '';
  content: '';
  width: 13px;
  height: 13px;
  display: inline-block;
  background: #009669;
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: -5px;
}


</style>